import { Helmet } from "react-helmet";
import { Text, Button, Img, Input, Heading } from "../../../components";
import Header from "../../../components/Header";
import { Link, useNavigate } from "react-router-dom";

export default function VerifyAccount() {
    const navigate = useNavigate();
  
    const handleSignUp = () => {
      // Aquí puedes añadir la lógica de registro, validaciones, etc.
      // Después de eso, redirige al usuario a la página deseada
      navigate('/VerifyAccount'); // Asegúrate de que esta ruta sea correcta y esté definida en tu enrutador
    };


  return (
    <>
      <Helmet>
        <title>Login Authentication - Secure Access to Your Plays</title>
        <meta
          name="description"
          content="Welcome back! Securely log in to find more plays using your email or phone number. Forgot your password? Easily recover access or sign in with your Google account."
        />
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-[84px] bg-neutral-neutral_border__day_ md:gap-[63px] sm:gap-[42px]">
        <Header />
        <div className="flex w-full max-w-md min-w-[320px] min-h-[500px] items-center justify-center bg-white-a700 border border-neutral-neutral_border__day_ rounded-[16px] p-[34px] md:w-full sm:p-5">
          <div className="w-full flex flex-col items-center gap-[22px]">
            <div className="mt-1 flex w-full max-w-xs flex-col items-center gap-2 md:w-full">
              <Img src="Images/SignUp-img/Frame 503.png" alt="Landing Success" className="h-[60px] w-[120px]" />
              <div className="flex flex-col items-center self-stretch">
              <Heading size="heading_h5" as="h1" className="!text-neutral-neutral_1100__day_  ">
                  Add a payment method 
                </Heading>
                <Text as="p" className="text-neutral-neutral_500__day_ font-light ">
                  7 Day Free Trial. Cancel Anytime
                </Text>
              </div>
            </div>
            
            <div className="flex gap-0 ">
            <Img src="Images/SignUp-img/image 1505.png" alt="Landing Success" className="h-[32px] w-[32px] mt-2 " />
            <Img src="Images/SignUp-img/Item-2.png" alt="Landing Success" className="h-[45px] w-[316px] " />     
            <Img src="Images/SignUp-img/image 1500.png" alt="Landing Success" className="h-[32px] w-[32px] mt-2 " />
            </div>
           <div className="flex flex-col items-center gap-[18px] self-stretch">
 
           <div className="flex flex-col items-start gap-0.5 self-stretch font-extralight">
                <Text as="p"className="font-light">Full Name on Card</Text>
                <Input
                  shape="round"
                  type="text"
                  name="Name Field"
                  placeholder="John F Smith"
                  suffix={<Img src="Images/sms.png" alt="SMS Icon" className="h-[16px] w-[18px]" />}
                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_ "
                />
              </div>

              <div className="flex flex-col items-start gap-0.5 self-stretch font-extralight">
                <Text as="p"className="font-light">Credit Card Number</Text>
                <Input
                  shape="round"
                  type="text"
                  name="CCN Field"
                  maxLength={19}
                  placeholder="1234 1234 1234 1234"
                  suffix={<Img src="Images/SignUp-img/card.png" alt="SMS Icon" className="h-[16px] w-[18px]" />}
                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_ "
                />
              </div>
              <div className="flex gap-4">
  <div className="flex flex-col font-extralight">
    <Text as="p"className="font-light">Expiration Date</Text>
    <Input
      shape="round"
      type="text" // Cambiado de "Date" a "text"
      name="Date Field"
      placeholder="MM/YY" // Indica el formato esperado
      className="flex items-center border border-solid border-neutral-neutral_border_day_ py-2 px-4"
    />
  </div>

  <div className="flex flex-col font-extralight">
    <Text as="p"className="font-light">CVV</Text>
    <Input
      shape="round"
      type="text" // Cambiado de "Password" a "text"
      name="CVV Field"
      placeholder="***" // O puede ser "CVV" si prefieres
      suffix={<Img src="Images/SignUp-img/info.png" alt="CVV Icon" className="h-[14px] w-[16px]" />} // Ajusta el ícono a algo relevante para CVV
      className="flex items-center border border-solid  border-neutral-neutral_border_day_ py-2  px-4"
    />
  </div>
</div>

              <div className="flex flex-col items-start gap-1 self-stretch">
                <div className="flex flex-col items-start gap-1 self-stretch">
                <Text as="p"className="font-light">Zip Code</Text>
                <Input
                  shape="round"
                  type="ZipCode"
                  name="ZipCode Field"
                  placeholder="90210"
                  className="gap-4 self-stretch border border-solid border-neutral-neutral_border_day_"
                />
              </div>
              </div>
              <div className="flex items-center gap-2">
                      <input
                   type="checkbox"
                   id="subscribe"
                    className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                       />
                        <label htmlFor="subscribe" className="text-neutral-neutral_500__day_ font-extralight ">
                   I agree this info is correct
                           </label>
                           </div>

              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="blue_A200_purple_A200"
                className="self-stretch font-semibold text-white-a700"
                onClick={handleSignUp}
              >
                Confirm Payment
              </Button>
              <a href="/#" className="self-end">
              </a>
              
              <div className="p-4">
             <div className="flex items-center space-x-2">
               <p className="text-blue-gray-400">
                  <span className="text-neutral-neutral_1100_day_">
            Already have an account?
           </span>
        </p>
           <Link 
              to="/lightauthenticationlogin" 
               className="text-deep_purple-400 bg-deep-purple-500 font-light py-1  rounded hover:bg-deep-purple-600"
               >
               Sign in
              </Link>
             </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
