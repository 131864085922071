import { Img } from "./..";
import React from "react";

interface Props {
  className?: string;
}

export default function Header({ className, ...props }: Props) {
  return (
    <header {...props} className={`self-stretch bg-neutral-neutral_bg__day_ ${className}`}>
      <div className="mt-[18px] flex w-full flex-col items-start gap-3.5">

          <div className="flex flex-1 items-center justify-between gap-5 md:self-stretch sm:flex-col">
            <Img
              src="./Images/LogoDark.png"
              alt="Header Logo"
              className="h-[60px] w-[148px] object-contain" // Ajustado a "w-[148px]"
            />

          </div>

        </div>
        <div className="h-px w-[84%] self-end bg-neutral-neutral_border_day_" />

    </header>
  );
}
