import { Helmet } from "react-helmet";
import { Img, Button, Heading } from "../../components/HomeCom";
import Header from "../../components/HomeCom/Header";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Crypto Exchange - Secure & Efficient Trading | Cryco</title>
        <meta
          name="description"
          content="Join Cryco, the trusted crypto exchange platform. Secure digital assets, instant transactions, and a comprehensive asset selection for an unmatched trading experience. Start trading Bitcoin, Ethereum, and more today."
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
        <style>
          {`
            body {
              font-family: 'Poppins', sans-serif;
            }
            .font-manrope {
              font-family: 'Manrope', sans-serif;
            }
          `}
        </style>
      </Helmet>
      <div className="relative bg-[url(/public/Images/HomePag-img/Body1.png)] bg-deep_purple-a400 bg-center  bg-cover  bg-no-repeat  lg:py-1 w-full">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center gap-7">
            <Header />
            <div className="relative w-[307px] h-[70px] right-9 top-60 lg:bg-none bg-[url(https://c.animaapp.com/zRCjfH7T/img/rectangle-9879.png)] bg-[100%_100%]">
              <div className="w-0.5 h-[70px] lg:bg-none [background:linear-gradient(180deg,rgb(92,143,255)_0%,rgb(193,75,255)_100%)]" />
            </div>
            <div className="flex flex-col items-center z-50 gap-5 mt-20 lg:mt-0 text-center">
              <Heading
                size="heading5xl"
                as="h3"
                className="text-center text-2xl lg:text-5xl font-manrope leading-[65px] tracking-[-.80px] md:text-3xl sm:text-2xl"
              >
                Cheat codes for <br />
                sports betting
              </Heading>

              <Heading
                size="texts"
                as="h4"
                className="text-center text-sm md:text-xl lg:text-base py-7 leading-[30px] text-indigo-100_02 font-manrope"
              >
                Welcome to our trusted Cryco crypto exchange. Security is <br />
                paramount. Trade with confidence.
              </Heading>
            </div>

            <div className="flex flex-row gap-4 lg:flex-wrap  sm:flex-row sm:gap-8 justify-center">
              <Button
                size="3xl"
                variant="gradient"
                color="blue_A200_purple_A200"
                rightIcon={
                  <div className="flex h-3 w-3 items-center justify-center">
                    <Img
                      src="./Images/HomePag-img/ArrowDa.png"
                      alt="Arrow Left"
                      className="h-3 w-3"
                    />
                  </div>
                }
                className="w-full max-w-xs rounded-[34px] font-bold"
              >
                Get Started
              </Button>
              <Button
                color="gray_900_02"
                size="3xl"
                rightIcon={
                  <div className="flex h-3 w-3 items-center justify-center">
                    <Img
                      src="Images/HomePag-img/ArrowDa.png"
                      alt="Arrow Left"
                      className="h-3 w-3"
                    />
                  </div>
                }
                className="w-full max-w-xs rounded-[34px] border border-solid border-white-a700_01 font-bold"
              >
                Explore More
              </Button>
            </div>

            <div className=" flex flex-col items-center  mt-1">
              <Img
                src="./Images/HomePag-img/Group546.png"
                alt="Product Image"
                className="w-[15%] h-auto md:w-[10%] lg:w-[15%] lg:top-[65%]  absolute  left-[-1px] top-[30%]"
              />
            </div>

            <div className=" bg-deep_purple-a400_2d px-4 py-5 rounded-[20px] border border-solid border-white-a700_47">
              <div className="rounded-[18px]  bg-white-a700_01">
                <Img
                  src="./Images/Dash.png"
                  alt="Screens"
                  className="w-[100%] h-[10%] rounded-lg object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*}
      <div className="w-full bg-deep_purple-a400 ">
        <div className="h-[80px] w-full bg-gradient blur-[200.00px] backdrop-opacity-[0.5]" />

        <div className="relative flex flex-col items-center justify-end w-full">
          <div className="relative flex w-full max-w-screen-xl bg-gradient py-16 px-4 md:px-10 blur-[100.00px] backdrop-opacity-[0.5]"></div>

          <InfiniteSlider />
          <div className="relative mt-[8%] mb-[10%] lg:py-1 w-full">
            <div className="absolute bottom-0 left-0 right-0 h-2 w-full max-w-screen-xl bg-gradient blur-[100.00px] backdrop-opacity-[1.5]" />
            <HowItWorksSection />
          </div>
          <div className="absolute bottom-0 left-0 right-0 h-1/2 w-full max-w-screen-xl bg-gradient blur-[500.00px] backdrop-opacity-[1.5]" />
          <FeaturesSection />
          <div className="relative w-full items-center ">
            <PopularCryptocurrenciesSection />
          </div>
          <div className="relative mt-[100px] py-1 lg:py-20 w-full">
            <div className="absolute bottom-5 left-0 right-0 h-1/2 w-full max-w-screen-xl bg-gradient blur-[1000.00px] backdrop-opacity-[0.5]" />
            <TestimonialSection />
            <div className="items-end mt-[-500px]">
              <div className="h-[938px] w-full bg-gradient blur-[500.00px] backdrop-opacity-[0.5]" />
              <FAQSection />
            </div>
            <div className="relative z-1 bg-[url(/public/Images/HomePag-img/Body.png)] bg-center bg-cover bg-no-repeat py-40 lg:py-20 w-full">
              <Img
                src="./Images/RoundRanner.png"
                alt="Round Banner"
                className="absolute left-0 right-0 mx-auto h-[150px] w-[150px] object-cover md:h-[200px] md:w-[200px] lg:top-[-50px] lg:h-[250px] lg:w-[250px]"
              />
              <ContactFormSection />
            </div>
          </div>
        </div>
      </div>
      {*/}
    </>
  );
}
