import React, { useState, useEffect, useMemo } from "react";
import type { ColumnDef } from "@tanstack/react-table";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel, getPaginationRowModel, PaginationState, SortingState } from "@tanstack/react-table";
import { ArrowLeft2, ArrowRight2, ArrowUp2, ArrowDown2 } from 'iconsax-react';

type ReactTableProps<D extends unknown> = {
  data: D[];
  columns: ColumnDef<D, string>[];
  headerProps?: React.HTMLProps<HTMLTableSectionElement>;
  headerCellProps?: React.HTMLProps<HTMLTableCellElement>;
  bodyProps?: React.HTMLProps<HTMLTableSectionElement>;
  rowDataProps?: { className?: string };
  cellProps?: { className?: string };
  size?: keyof typeof sizes;
  [x: string]: any;
};

const sizes = {
  xs: "py-4.5 w-100%]",
  sm: "py-5",
  m: "py-5",
} as const;

const ReactTable = <D extends unknown>({
  columns,
  data = [],
  headerProps = {},
  headerCellProps = {},
  bodyProps = {},
  className = "",
  rowDataProps = { className: "" },
  cellProps = { className: "" },
  size,
  ...restConfig
}: ReactTableProps<D>) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const tableConfig = useMemo(() => ({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
      sorting
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    ...restConfig,
  }), [columns, data, pagination, sorting, restConfig]);

  const table = useReactTable(tableConfig);

  useEffect(() => {
    if (pagination.pageIndex > 0 && table.getPageCount() > 0 && pagination.pageIndex >= table.getPageCount()) {
      setPagination(prev => ({ ...prev, pageIndex: table.getPageCount() - 1 }));
    }
  }, [data, pagination.pageIndex, table]);

  useEffect(() => {
    table.setPageSize(50);
  }, [table]);

  // Render the UI for your table
  return (
    <>
      <table className={className}>
        <thead {...headerProps}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} {...header.column.columnDef?.meta} {...headerCellProps}>
                  {header.isPlaceholder ? null : (
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer flex mx-auto  items-center "
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === "desc" ? (
                          <ArrowDown2 variant="Bold" className="inline-block pb-3" />
                        ) : (
                          <ArrowUp2 variant="Bold" className="inline-block pb-3" />
                        )
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...bodyProps}>
          {table.getRowModel().rows.map((row) => (
            <tr {...rowDataProps} className={rowDataProps.className} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={`${size ? sizes[size] : ""} ${cellProps.className}`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center p-4">
        <div>
          Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to{' '}
          {Math.min((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize, data.length)} of {data.length} entries
        </div>
        <div className="flex items-center gap-2">
          <button 
            onClick={() => table.previousPage()} 
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-1 border border-purple-600 rounded-3xl text-white disabled:opacity-50"
          >
            <ArrowLeft2 className="inline-block" /> Previous
          </button>
          <span>
            Page{' '}
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
          <button 
            onClick={() => table.nextPage()} 
            disabled={!table.getCanNextPage()}
            className="px-3 py-1 border border-purple-600 rounded-3xl text-white disabled:opacity-50"
          >
           Next <ArrowRight2 className="inline-block" /> 
          </button>
        </div>
      </div>
    </>
  );
};

export { ReactTable };
