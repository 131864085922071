/* eslint-disable react-hooks/exhaustive-deps */
import "./Buttons.css";
import { Helmet } from "react-helmet";
import { Text, Img, Input } from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ARBITRAGE_REQUEST } from "components/queries/allQueries";
import { useAuth } from "components/auth/AuthProvider";

import { Select, Switch } from "antd";
import Header from "components/Dashboard/Header";
import { CloseCircle, Refresh } from "iconsax-react";

const { Option } = Select;

interface CustomSwitchProps {
  isActive: boolean;
  activeColor: string;
  [key: string]: any;
}

const AntSelectBox = ({ ...props }) => {
  return (
    <Select mode="multiple" maxTagCount={2} {...props} style={{ width: "25%" }}>
      {leagueOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};


const CustomSwitch: React.FC<CustomSwitchProps> = ({
  isActive,
  activeColor,
  ...props
}) => (
  <Switch
    {...props}
    checked={isActive}
    style={{
      backgroundColor: isActive ? activeColor : "",
      borderColor: isActive ? activeColor : "#B3261E",
    }}
  />
);

// Uso d

const leagueOptions = [
  { label: "WNBA", value: "wnba" },
  { label: "NBA", value: "nba" },
  { label: "NFL", value: "nfl" },
  { label: "MLB", value: "mlb" },
];

const marketOptions: options[] = [
  { label: "Over/Under", value: "o/u" },
  { label: "Spread", value: "sp" },
  { label: "Moneyline", value: "ml" },
  { label: "Unknown", value: "uk" },
];

const bookOptions: options[] = [
  { label: "Fanduel", value: "fanduel" },
  { label: "Draftkings", value: "draftkings" },
  { label: "ESPNBet", value: "espnbet" },
  { label: "Fanatics", value: "fanatics" },
  { label: "BetMGM", value: "betmgm" },
  { label: "BetRivers", value: "betrivers" },
  { label: "Pinnacle", value: "pinnacle" },
  { label: "Hardrock", value: "hardrock" },
];

type options = {
  label: string;
  value: string;
};

type Table3RowType = {
  leagueLabel: string;
  marketLabel: string;
  nameLabel: string;
  handicapLabel: string;
  betTypeLabel: string;
  wagerLabel: string;
  payoutLabel: string;
  profitLabel: string;
  liveLabel: boolean | string;
  middleLabel: boolean | string;
  booksLabel: booksLabel | string;
};

type booksLabel = {
  book1: book;
  book2: book;
};

type book = {
  name: string;
  odds: string;
  link: string;
};

type ArbitrageData = {
  id: string;
  name: string;
  league: string;
  event: string;
  teamName1?: string;
  teamName2?: string;
  market: string;
  handicap: string;
  amount: string;
  payout: string;
  live: boolean;
  middle: boolean;
  percent: number;
  book1: ArbitrageBook;
  book2: ArbitrageBook;
};

type ArbitrageBook = {
  name: string;
  url: string;
  odds: string;
  bet: string;
};

function arraysMatchUnordered(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

function convertMarketOption(opt: string): string {
  switch (opt) {
    case "OVER":
      return "O/U";
    case "UNDER":
      return "O/U";
    case "MONEYLINE":
      return "ML";
    case "SPREAD":
      return "SP";
    default:
      return "UK";
  }
}

export default function ArbitragePage() {
  const [keywordOption, setKeywordOption] = useState("");
  const { tokens } = useAuth();
  const [countdown, setCountdown] = useState(15);
  const [arbs, setArbs] = useState<Table3RowType[]>([]);
  const [filteredArbs, setFilteredArbs] = useState<Table3RowType[]>([]);
  const [leagueOption, setLeagueOption] = useState<options[]>([]);
  const [marketOption, setMarketOption] = useState<options[]>([]);
  const [bookOption, setBookOption] = useState<options[]>([]);
  const [fetchArbitrage] = useLazyQuery(FETCH_ARBITRAGE_REQUEST);
  const [isLive, setIsLive] = useState(true);
  const [isMiddles, setIsMiddles] = useState(true);

  const handleFetchArbitrage = useCallback(async () => {
    try {
      const response = await fetchArbitrage({
        variables: {
          access_token: tokens.accessToken,
          filter: {
            sports: leagueOptions.map((option) => option.value),
            books: bookOption.map((option) => option.value),
            markets: marketOption.map((option) => option.value),
            minimumPercent: 1,
            includeLives: true,
            includeMiddles: true,
          },
        },
      });
      // Handle the response here
      if (response.data.fetchArbitrage.success) {
        const newArbs: Table3RowType[] = [];
        for (const arb of response.data.fetchArbitrage
          .data as ArbitrageData[]) {
          newArbs.push({
            leagueLabel: arb.league.toUpperCase(),
            marketLabel: arb.market,
            nameLabel: arb.name,
            handicapLabel: arb.handicap,
            betTypeLabel: convertMarketOption(arb.book1.bet),
            wagerLabel: arb.amount,
            payoutLabel: arb.payout,
            profitLabel:
              (Number(arb.payout) - Number(arb.amount)).toFixed(2) + "",
            liveLabel: arb.live,
            middleLabel: arb.middle,
            booksLabel: {
              book1: {
                name: arb.book1.name,
                odds: arb.book1.odds,
                link: arb.book1.url,
              },
              book2: {
                name: arb.book2.name,
                odds: arb.book2.odds,
                link: arb.book2.url,
              },
            },
          });
        }
        if (!arraysMatchUnordered(newArbs, arbs)) {
          setArbs(newArbs);
        }

        console.log("Fetching arbitrage successful", response.data.login);
      } else {
        console.error("Fetching arbitrage failed:", response.data.login.error);
      }
    } catch (err) {
      console.error("Error fetching arbitrage:", err);
    }
    setCountdown(3)
  }, [arbs, bookOption, fetchArbitrage, marketOption, tokens.accessToken]);

  useEffect(() => {
    let kw = keywordOption.trim().toLowerCase()
    let filteredArbs: Table3RowType[] = arbs;
    if (!isLive) {
      filteredArbs = filteredArbs.filter((arb) => !arb.liveLabel);
    }

    if (!isMiddles) {
      filteredArbs = filteredArbs.filter((arb) => !arb.middleLabel);
    }

    if (bookOption && bookOption.length > 1) {
      filteredArbs = filteredArbs.filter(
        (arb) =>
          bookOption.some(
            (book) =>
              (arb.booksLabel as booksLabel).book1.name.toLowerCase() ===
              String(book),
          ) &&
          bookOption.some(
            (book) =>
              (arb.booksLabel as booksLabel).book2.name.toLowerCase() ===
              String(book),
          ),
      );
    }

    if (marketOption && marketOption.length > 0) {
      filteredArbs = filteredArbs.filter((arb) =>
        marketOption.some(
          (option) => arb.betTypeLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (leagueOption && leagueOption.length > 0) {
      filteredArbs = filteredArbs.filter((arb) =>
        leagueOption.some(
          (option) => arb.leagueLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (kw && kw !== '') {
      filteredArbs = filteredArbs.filter((arb) => arb.nameLabel.toLowerCase().includes(kw) || arb.leagueLabel.toLowerCase().includes(kw) || arb.marketLabel.toLowerCase().includes(kw));  
    }
    setFilteredArbs(filteredArbs);
  }, [
    isMiddles,
    isLive,
    arbs,
    setFilteredArbs,
    bookOption,
    marketOption,
    leagueOption,
    keywordOption,
  ]);

  useEffect(() => {
    handleFetchArbitrage();
    const fa = setInterval(() => {
      handleFetchArbitrage();
    }, 3 * 1000); // every 3 seconds
    return () => clearInterval(fa);
  },[]);

  useEffect(() =>{
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
    }, 1000);

    return () => clearInterval(timer)
  },[]);


  useEffect(() => {
    console.log("leagueOption changed", leagueOption);
  }, [leagueOption]);

  useEffect(() => {
    console.log("marketOption changed", marketOption);
  }, [marketOption]);

  
  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
   
      table3ColumnHelper.accessor("liveLabel", {
        cell: (info) => {
          const middleLabelValue = info.row.original.middleLabel === true;  
          const isLive = info.getValue<boolean>() === true && !middleLabelValue; 
          
          console.log("liveLabel value:", isLive); 
          console.log("middleLabel value:", middleLabelValue); 
      
          return (
            <div className="flex items-center gap-2">
              {middleLabelValue ? (
                <img
                  alt="middle"
                  src="./Images/BooksIcon/middles.png"
                  style={{ width: "12%", height: "100%" }}
                />
              ) : isLive ? (
                <img
                  alt="live"
                  src="./Images/BooksIcon/live.png"
                  style={{ width: "12%", height: "100%" }} 
                />
              ) : (
                <img
                  alt="normal"
                  src="./Images/BooksIcon/Normal.png"
                  style={{ width: "12%", height: "100%", padding: 0, margin: 0 }}
                />
              )}
            </div>
          );
        },
        header: (info) => <Text />,
        meta: { width: "2%" },
      }),
      
      
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            {info.getValue<any>() === "WNBA" && (
              <img
              alt=""
              src="./Images/BooksIcon/wnba.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NBA" && (
              <img
              alt=""
              src="./Images/BooksIcon/nba.svg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NFL" && (
              <img
              alt=""
              src="./Images/BooksIcon/nfl.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "MLB" && (
              <img
              alt=""
              src="./Images/BooksIcon/mlb.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            League
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "15%" },
      }),
      table3ColumnHelper.accessor("betTypeLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Market
          </Text>
        ),
        meta: { width: "10%" },
      }),



      table3ColumnHelper.accessor("wagerLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Wager
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("payoutLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Payout
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("profitLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Profit
          </Text>
        ),
        meta: { width: "5%" },
      }),
      table3ColumnHelper.accessor("booksLabel", {
        cell: (info) => (
          <div className="button-container">
            {info.getValue<booksLabel>().book1.name === "Fliff" && (
              <img
              alt=""
              src="./Images/BooksIcon/getfliff.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "ESPNBet" && (
              <img
              alt=""
              src="./Images/BooksIcon/espnbet.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Fanatics" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanatics.webp"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Caesars" && (
              <img
              alt=""
              src="./Images/BooksIcon/caesars.webp"
                style={{ width: "10%", height: "15%" }}
              />
            )}

            {info.getValue<booksLabel>().book1.name === "BetRivers" && (
              <img
              alt=""
              src="./Images/BooksIcon/BetRivers.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "BetMGM" && (
              <img
              alt=""
              src="./Images/BooksIcon/BetMGM.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "fanduel" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanduel.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Fanduel" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanduel.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Draftkings" && (
              <img
              alt=""
              src="./Images/BooksIcon/draftkings.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "draftkings" && (
              <img
              alt=""
              src="./Images/BooksIcon/draftkings.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Pinnacle" && (
              <img
              alt=""
              src="./Images/BooksIcon/Pinnacle.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book1.name === "Hardrock" && (
              <img
              alt=""
              src="./Images/BooksIcon/hardrock.webp"
                style={{ width: "10%", height: "15%" }}
              />
            )}
              {info.getValue<booksLabel>().book1.name === "Bet365" && (
              <img
              alt=""
              src="./Images/BooksIcon/bet365.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}

            <button
              className="custom-button"
              style={{
                backgroundColor: info
                  .getValue<booksLabel>()
                  .book1.odds.startsWith("-")
                  ? "#E8EDF2"
                  : info.getValue<booksLabel>().book1.odds.startsWith("")
                    ? "rgba(80, 209, 178, 0.2)"
                    : "initial",
                color: info.getValue<booksLabel>().book1.odds.startsWith("-")
                  ? "black" // Color de texto para el fondo gris
                  : info.getValue<booksLabel>().book1.odds.startsWith("")
                    ? "#2B9B80" // Color de texto para el fondo verde claro
                    : "initial", // Color de texto por defecto
              }}
              onClick={() =>
                window.open(info.getValue<booksLabel>().book1.link, "_blank")
              }
            >
              {info.getValue<booksLabel>().book1.odds}
            </button>

            {info.getValue<booksLabel>().book2.name === "Fliff" && (
              <img
              alt=""
              src="./Images/BooksIcon/getfliff.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "ESPNBet" && (
              <img
              alt=""
              src="./Images/BooksIcon/espnbet.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "Fanatics" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanatics.webp"
                style={{ width: "10%", height: "15%" }}
              />
            )}
              {info.getValue<booksLabel>().book2.name === "Caesars" && (
              <img
              alt=""
              src="./Images/BooksIcon/caesars.webp"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "BetRivers" && (
              <img
              alt=""
              src="./Images/BooksIcon/BetRivers.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "BetMGM" && (
              <img
              alt=""
              src="./Images/BooksIcon/BetMGM.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "fanduel" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanduel.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "Fanduel" && (
              <img
              alt=""
              src="./Images/BooksIcon/fanduel.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "Draftkings" && (
              <img
              alt=""
              src="./Images/BooksIcon/draftkings.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "draftkings" && (
              <img
              alt=""
              src="./Images/BooksIcon/draftkings.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "Pinnacle" && (
              <img
              alt=""
              src="./Images/BooksIcon/Pinnacle.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<booksLabel>().book2.name === "Hardrock" && (
              <img
              alt=""
                src="./Images/BooksIcon/hardrock.webp"
                style={{ width: "10%", height: "15%" }}
              />
            )}
             {info.getValue<booksLabel>().book2.name === "Bet365" && (
              <img
              alt=""
              src="./Images/BooksIcon/bet365.png"
                style={{ width: "10%", height: "15%" }}
              />
            )}

            <button
              className="custom-button"
              style={{
                backgroundColor: info
                  .getValue<booksLabel>()
                  .book2.odds.startsWith("-")
                  ? "#E8EDF2"
                  : info.getValue<booksLabel>().book2.odds.startsWith("")
                    ? "rgba(80, 209, 178, 0.2)"
                    : "initial",
                color: info.getValue<booksLabel>().book2.odds.startsWith("-")
                  ? "black" // Color de texto para el fondo gris
                  : info.getValue<booksLabel>().book2.odds.startsWith("")
                    ? "#2B9B80" // Color de texto para el fondo verde claro
                    : "initial", // Color de texto por defecto
              }}
              onClick={() =>
                window.open(info.getValue<booksLabel>().book2.link, "_blank")
              }
            >
              {info.getValue<booksLabel>().book2.odds}
            </button>
          </div>
        ),
        header: (info) => <Text as="p"></Text>,

        meta: { width: "20%" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Arbitrage Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports arbitrage betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
          <div className="relative h-[90px] md:h-auto">
              <Header />
  
            </div>
            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
                <div className="flex flex-1 mr-7 ml-5 py-2 justify-center md:self-stretch md:px-5">
                  <Input
                    name="Search Bar"
                    placeholder="Enter your keyword..."
                    value={keywordOption}
                    onChange={(kw)=>setKeywordOption(kw.target.value)}
                    prefix={
                      <Img
                        src="/Images/Dashboard-img/search.png"
                        alt="Contrast"
                        className="my-1 h-[100%] w-[100%]"
                      />
                    }
                    suffix={
                      keywordOption.length > 0 ? (
                        <CloseCircle

                        onClick={() => setKeywordOption("")}
    
                      />
                      ) : null
                    }
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[14px] text-blue_gray-300 md:w-full md:px-5"
                  />
                  <Input
                    name="Search Bar"
                    placeholder="Min Profit %"
                    className="flex h-[48px] w-[15%] ml-4 items-center justify-center gap-2 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[14px] text-blue_gray-300 md:w-full md:px-5"
                  />
                  <AntSelectBox
                    placeholder="Select leagues"
                    value={leagueOption}
                    onChange={(opt: React.SetStateAction<options[]>) =>
                      setLeagueOption(opt)
                    }
                    className="ml-3"
                  />

                  <AntSelectBox
                    placeholder="Select Market"
                    value={marketOption}
                    options={marketOptions}
                    onChange={(opt: options[]) => setMarketOption(opt)}
                    className="ml-3  w-[15%]"
                  />
                  <AntSelectBox
                    placeholder="Select Books"
                    options={bookOptions}
                    onChange={(opt: options[]) => setBookOption(opt)}
                    value={bookOption}
                    className="ml-3  w-[15%]"
                  />

                  <div className="flex items-center">
                    <CustomSwitch
                      isActive={isLive}
                      activeColor="#B3261E"
                      onChange={setIsLive}
                      className="ml-3"
                    />
                    <span className="ml-1 text-[14px] font-normal text-red-700">
                      Live
                    </span>
                  </div>
                  <div className="flex items-center">
                    <CustomSwitch
                      isActive={isMiddles}
                      activeColor="#c14bff"
                      onChange={setIsMiddles}
                      className="ml-3"
                    />
                    <span className="ml-1 text-[14px] font-normal text-transparent bg-gradient bg-clip-text">
                      Middles
                    </span>
                  </div>
                </div>
                <div className="rounded-[16px] ml-5 border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                <div className="flex ">
                <p className=" text-left mr-2 text-[14px]  font-normal text-neutral-neutral_500__day_" > Auto refresh:{countdown} </p>
                <button onClick={handleFetchArbitrage} >    <Refresh className="w-[15px] h-[15px] " /> 
                </button>
                </div>
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={filteredArbs}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
