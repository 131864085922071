import React, { useState } from 'react';
import { Button, Img } from "../../HomeCom";
import { Menu, Dropdown } from 'antd';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import "./Menu.css";

interface Props {
  className?: string;
}

const createMenu = (items: { key: string; label: string; path: string }[], navigate: (path: string) => void) => (
  <Menu>
    {items.map((item) => (
      <Menu.Item key={item.key} onClick={() => navigate(item.path)}>
        {item.label}
      </Menu.Item>
    ))}
  </Menu>
);

const Header: React.FC<Props> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const homeMenu = createMenu(
    [
      { key: "1", label: "Subpage 1", path: "/home/subpage1" },
      { key: "2", label: "Subpage 2", path: "/home/subpage2" },
    ],
    navigate
  );

  const blogMenu = createMenu(
    [
      { key: "1", label: "Subpage 1", path: "/blog/subpage1" },
      { key: "2", label: "Subpage 2", path: "/blog/subpage2" },
    ],
    navigate
  );

  const isActive = (path: string) => location.pathname === path;

  return (
    <header className={`${className}flex sm:flex-col  items-center p-4 bg-white shadow-md`}>
      {/* Logo Section */}
      <Img src="/Images/LogoDark.png" alt="Logo" className="w-45 mr-5 mx-auto lg:w-48 object-cover" />
      <div className="flex  items-center w-full lg:w-auto">

        <Button className="sm:block border rounded-full mt-3 capitalize text-lg hidden text-[100%] bg-transparent  mx-auto" onClick={toggleMenu}>
          Menu 
          <MenuOutlined className='ml-1' />
        </Button>
      </div>

      {/* Navigation Menu */}
      <nav className={`flex sm:flex-col  gap-4 ${isMenuOpen ? 'sm:hidden' : 'sm:block'} sm:flex`}>
        <Dropdown overlay={homeMenu} trigger={['click']} arrow>
          <Button className={`capitalize text-lg bg-transparent border-none ${isActive('/home') ? 'text-blue-500' : ''}`} aria-label="Home menu">
            Home <DownOutlined />
          </Button>
        </Dropdown>
        <Button onClick={() => navigate('/features')} shape="round" className={`capitalize text-lg bg-transparent${isActive('/features') ? 'text-blue-500' : ''}`} aria-label="Features">
          Features
        </Button>
        <Button onClick={() => navigate('/integration')} shape="round" className={`capitalize text-lg bg-transparent ${isActive('/integration') ? 'text-blue-500' : ''}`} aria-label="Integration">
          Integration
        </Button>
        <Dropdown overlay={blogMenu} trigger={['click']} arrow>
          <Button className={`capitalize text-lg bg-transparent border-none  ${isActive('/blog') ? 'text-blue-500' : ''}`} aria-label="Blog menu">
            Blog <DownOutlined />
          </Button>
        </Dropdown>
        <Button onClick={() => navigate('/contact')} shape="round" className={`capitalize text-lg bg-transparent ${isActive('/contact') ? 'text-blue-500' : ''}`} aria-label="Contact">
          Contact
        </Button>
        <Button onClick={() => navigate('/lightauthenticationlogin')} shape="round"
         leftIcon={<Img src="Images/HomePag-img/user.png" alt="User" className="mb-1 h-[18px] w-[12px] object-cover" />} 
         className="min-w-[102px] text-lg gap-2 border border-blue-gray-800 font-bold capitalize bg-transparent" aria-label="Login">
          Login
        </Button>
      </nav>
    </header>
  );
};

export default Header;
